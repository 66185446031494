export const aboutgoldCoastData = [
  { heading: "About Gold Coast Retreat", 

    subHeading: "Privacy is our policy, the West Coast, our inspiration",

    paragraph1: 
    "At Gold Coast Retreat you bathe in the pristine waters of the Clayoquot Sound and let your troubles fade away. " +
    "Our cabin’s feature outdoor solariums with cast iron, clawfoot tubs… Bubbles included. The soothing sounds of crashing surf serve as " +
    "a constant reminder during your stay. From your doorstep, just across the lane, a forested beach path takes you to the famous Chesterman Beach. " +
    "For the un-initiated it is a wildlife viewing, beach combing and surfing paradise. The village centre in Tofino is a short 6 kilometres away. " +
    "Drive or ride your bike on the multi-use path. In the opposite direction the famous Pacific Rim National Park Reserve is also a short 6km drive or ride away.", 

    image1: "about-image.jpg",

    paragraph2: 
    "A DREAM OF TIME - Gold Coast Retreat opened it’s doors in 1992 as Tofino’s first vacation rental before the arrival of internet advertising. " +
    "Times have changed and we continue to change with the times. At the Gold Coast Retreat cottages, our guests enjoy all the comforts and modern convieniences of home " +
    "with the litte extras that comfort your body and soul. Gold coast retreat is the complete Tofino vacation. " +
    "We can’t wait to introduce you to the magic of Chesterman beach.", 

    paragraph3: "LOUISE - She is the organizer. A woman of the sea with many talents. Louise skippered a commercial dive boat for 20 years. She has travelled all along the coast of Vancouver Island. She has seen many storms that aren’t for the faint hearted.", 
    paragraph4: "SULO - He is the builder. A commercial fisherman by trade, he has caught Salmon, Herring, Rock-Fish and Halibut all over the west coast. His passion for his old career has translated to his new one and he loves this one even more.", 
  }
]