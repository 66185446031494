import React from "react"
import {aboutgoldCoastData} from "../data/aboutgoldcoastData"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Info from "../components/info"
import CabinsFullWidth from "../components/cabinsFullWidth"
import { useStaticQuery, graphql } from "gatsby"

const AboutGoldCoast = () => {  

  const image = useStaticQuery(graphql`
    query {
      file(name: {eq: "about-image"}) {
        childImageSharp {
          fluid (maxWidth:500) {
             ...GatsbyImageSharpFluid_noBase64
           }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About Gold Coast" />
      <Info props={aboutgoldCoastData} image={image}/>
      <CabinsFullWidth />
    </Layout>
  )
}

export default AboutGoldCoast