import React from 'react'
import styled from "styled-components"
import Img from "gatsby-image"
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"
import PageHeader from "../elements/pageHeader"
import { useStaticQuery, graphql } from "gatsby"

const About = ({props, image}) => {

  const messageImage = useStaticQuery(graphql`
    query {
      file(name: {eq: "a-message"}) {
        childImageSharp {
          fluid(maxWidth:500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  
  return (
    <>
    {props.map((item, index) => (
      <>
    <PageHeader header={item.heading} subHeader={item.subHeading}/>
    <InfoContainer>
          <Top>
            <Paragraph1 key={index}>
              {item.paragraph1}
            </Paragraph1>
            <Image fluid={image.file.childImageSharp.fluid} alt="sunset image"/>
          </Top>          
          <Middle>
            <Paragraph2>
              {item.paragraph2}
            </Paragraph2>
            <ComeStayContainer>
              <MessageImage fluid={messageImage.file.childImageSharp.fluid} alt="Come Stay Wiht Us - A message from the Sea"/>
            </ComeStayContainer>
          </Middle>
          <Bottom>
            <Paragraph3>
              {item.paragraph3}
            </Paragraph3>
            <Paragraph4>
              {item.paragraph4}
            </Paragraph4>
          </Bottom>
    </InfoContainer>
    </>
    ))}
    </>
  )
}

export default About

const InfoContainer = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #000;
  background-image: url(${backgroundImageSand});
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`

const Top = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`

const Image = styled(Img)`
  border-radius: 50%;
  width: 300px;
  height: 100%;
  align-self: center;
  margin: auto;

  @media screen and (max-width: 868px){
    width: 50%;
    height: 100%;
  }
`

const Middle = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
  }
`

const ComeStayContainer = styled.div`
  width: 250px;
  align-self: center;
  align-items: center;
  margin: 0 auto;
`

const MessageImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const Bottom = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
`

const Paragraph1 = styled.p``
const Paragraph2 = styled.p``
const Paragraph3 = styled.p``
const Paragraph4 = styled.p``


